import { useEffect, useState } from 'preact/hooks';

import type { CountryType } from 'src/shared/types/countries.js';
import type { PriceCurrency, PriceInfo, PriceInfoKey } from 'src/shared/types/prices.js';
import type { Profession } from 'src/shared/types/professions.js';
import { getPrices } from 'src/shared/utils/get-prices/get-prices.js';

import { PRICE_COUNTRIES_MAP, PRICE_PROFESSION_MAP } from './maps.js';

export const usePrices = (country: CountryType, profession: Profession, priceCurrency: PriceCurrency) => {
    const [prices, setPrices] = useState<PriceInfo>();

    useEffect(() => {
        (async () => {
            const priceCountry = PRICE_COUNTRIES_MAP[country];
            const priceProfession = PRICE_PROFESSION_MAP[profession];

            if (!priceCountry || !priceProfession || !priceCurrency) return;

            try {
                const priceInfo = await getPrices();
                const priceInfoKey: PriceInfoKey = `${priceCountry}_${priceProfession}_${priceCurrency}`;

                setPrices(priceInfo[priceInfoKey]);
            } catch (error) {
                console.error(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return prices;
};
