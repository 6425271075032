import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnPriceBlock } from '../price-block.constants.js';

import './price-block__prices.css';

import './_has-discount/price-block__prices_has-discount.css';

export interface PriceBlockPricesProps {
    children?: ComponentChildren;
    hasDiscount: boolean;
}

export const PriceBlockPrices: FunctionalComponent<PriceBlockPricesProps> = ({ children, hasDiscount }) => (
    <div className={cnPriceBlock('prices', { 'has-discount': hasDiscount })}>{children}</div>
);
