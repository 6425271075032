import type { FunctionalComponent } from 'preact';

import { HtmlText } from 'src/shared/components/html-text/html-text.js';

import { PriceBlockPartners, type PriceBlockPartnersProps } from './__partners/price-block__partners.js';
import { PriceBlockPrices } from './__prices/price-block__prices.js';
import { cnPriceBlock } from './price-block.constants.js';

import './price-block.css';

import './__currency-code/price-block__currency-code.css';
import './__description/price-block__description.css';
import './__discount/price-block__discount.css';
import './__new-price/price-block__new-price.css';
import './__old-price/price-block__old-price.css';
import './__payment-type/price-block__payment-type.css';
import './__pretitle/price-block__pretitle.css';
import './__title/price-block__title.css';

import './_accent-block-color/price-block_accent-block-color_green.css';
import './_accent-block-color/price-block_accent-block-color_orange.css';
import './_accent-block-color/price-block_accent-block-color_red.css';
import './_color-theme/price-block_color-theme_dark.css';
import './_color-theme/price-block_color-theme_light.css';

type BlockAttributes = {
    pretitle: string;
    title: string;
    hasDiscount: boolean;
    discountPercent: string;
    oldPrice: string;
    newPrice: string;
    currencyCode: string;
    paymentType: string;
    description: string;
    colorTheme: 'light' | 'dark';
    accentColor: 'green' | 'orange' | 'red';
    partners?: PriceBlockPartnersProps['partners'];
};

export interface PriceBlockProps extends BlockAttributes {}

export const PriceBlock: FunctionalComponent<PriceBlockProps> = (props) => {
    const {
        pretitle,
        title,
        hasDiscount,
        discountPercent,
        oldPrice,
        newPrice,
        currencyCode,
        paymentType,
        partners,
        description,
        colorTheme,
        accentColor,
    } = props;

    return (
        <div className={cnPriceBlock({ 'color-theme': colorTheme, 'accent-block-color': accentColor })}>
            <div className={cnPriceBlock('pretitle')}>
                <HtmlText html={pretitle} />
                {hasDiscount && <div className={cnPriceBlock('discount')}>{discountPercent}</div>}
            </div>
            {hasDiscount && (
                <div className={cnPriceBlock('title')}>
                    <HtmlText html={title} />
                    <span className={cnPriceBlock('currency-code')}>{currencyCode}</span>
                </div>
            )}
            <PriceBlockPrices hasDiscount={hasDiscount}>
                {hasDiscount && <div className={cnPriceBlock('old-price')}>{oldPrice}</div>}
                <div className={cnPriceBlock('new-price')}>
                    {newPrice}
                    <span className={cnPriceBlock('payment-type')}>
                        {currencyCode} / {paymentType}
                    </span>
                </div>
            </PriceBlockPrices>
            <PriceBlockPartners partners={partners} />
            <div className={cnPriceBlock('description')}>
                <HtmlText html={description} />
            </div>
        </div>
    );
};
