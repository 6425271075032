import type { FormattedPriceInfo, PriceCurrency, PriceInfo, PriceValue } from 'src/shared/types/prices.js';

import { formatAmount, formatDefault, formatPercent } from './format.js';

const formatMap = {
    full_price: formatAmount,
    discount_percent: formatPercent,
    monthly_price: formatAmount,
    months_count: formatDefault,
    upfront_final_price: formatAmount,
    upfront_economy: formatAmount,
    monthly_final_price: formatAmount,
    monthly_economy: formatAmount,
};

export const defaultFormattedPriceInfo = Object.keys(formatMap).reduce<FormattedPriceInfo>((acc, key) => {
    acc[key as PriceValue] = '';

    return acc;
}, {} as FormattedPriceInfo);

export const getFormattedPrices = (prices: PriceInfo | undefined, currency: PriceCurrency) => {
    return Object.entries(prices ?? {}).reduce(
        (acc, [key, value]) => {
            const formatter = formatMap[key as PriceValue] || formatDefault;

            acc[key as PriceValue] = formatter(value, currency);

            return acc;
        },
        { ...defaultFormattedPriceInfo },
    );
};
