import type { PriceInfo, PriceInfoKey } from 'src/shared/types/prices.js';

const pricesUrl = import.meta.env.PUBLIC_PRICES_URL;

export const getPrices = (): Promise<Record<PriceInfoKey, PriceInfo>> => {
    if (pricesUrl === undefined) {
        throw new Error('PUBLIC_PRICES_URL is not defined');
    }

    return fetch(pricesUrl).then((response) => response.json());
};
