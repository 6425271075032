import type { ComponentChildren, FunctionalComponent } from 'preact';

import { MultilineText } from 'src/shared/components/multiline-text/multiline-text.js';

import { cnPriceShowcase } from '../price-showcase.constants.js';

import './price-showcase__legal.css';

export interface PriceShowcaseLegalProps {
    children?: ComponentChildren;
}

export const PriceShowcaseLegal: FunctionalComponent<PriceShowcaseLegalProps> = ({ children }) => (
    <MultilineText className={cnPriceShowcase('legal')}>{children}</MultilineText>
);
