import accedeLogo from './icons/accede.svg';
import mercadoLogo from './icons/mercado.svg';
import pagSeguroLogo from './icons/pagseguro.svg';
import payphoneLogo from './icons/payphone.svg';
import payuLogo from './icons/payu.svg';
import plataLogo from './icons/plata.svg';
import principiaLogo from './icons/principia.svg';
import proviLogo from './icons/provi.svg';
import rebillLogo from './icons/rebill.svg';
import stripeLogo from './icons/stripe.svg';
import type { FunctionalComponent } from 'preact';

import { cnPriceBlock } from '../price-block.constants.js';

import './price-block__partners.css';

export interface PriceBlockPartnersProps {
    partners?: (keyof typeof partnerLogos)[];
}

const partnerLogos = {
    provi: proviLogo,
    stripe: stripeLogo,
    mercadopago: mercadoLogo,
    pagseguro: pagSeguroLogo,
    accede: accedeLogo,
    plata: plataLogo,
    rebill: rebillLogo,
    principia: principiaLogo,
    payu: payuLogo,
    payphone: payphoneLogo,
};

export const PriceBlockPartners: FunctionalComponent<PriceBlockPartnersProps> = ({ partners }) => (
    <div className={cnPriceBlock('partners')}>
        {(partners || []).map((partner) => {
            if (partner in partnerLogos) {
                return <img key={partner} alt={partner} src={partnerLogos[partner].src} loading="lazy" />;
            }

            return null;
        })}
    </div>
);
