import type { PriceCurrency } from 'src/shared/types/prices.js';

const formatNumber = (locale: string, value: number) => {
    return Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(value);
};

export const formatAmount = (price: number, currency: PriceCurrency): string => {
    if (currency === 'BRL') {
        return `R$${formatNumber('pt-BR', price)}`;
    }

    if (currency === 'PEN') {
        return `S/.${formatNumber('es-PE', price)}`;
    }

    return `$${formatNumber('us-US', price)}`;
};

export const formatPercent = (value: number) => `${value}%`;

export const formatDefault = (value: number) => `${value}`;
