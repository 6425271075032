import type { FunctionalComponent } from 'preact';

import { useCohortDate } from 'src/shared/hooks/use-cohort-date/use-cohort-date.js';
import { getFormattedPrices } from 'src/shared/hooks/use-prices/get-formatted-prices.js';
import { usePrices } from 'src/shared/hooks/use-prices/use-prices.js';
import type { CountryType } from 'src/shared/types/countries.js';
import type { PriceCurrency } from 'src/shared/types/prices.js';
import type { Profession } from 'src/shared/types/professions.js';

import { PriceBlock } from '../price-block/price-block.js';
import type { PriceShowcaseProps } from './price-showcase.js';
import { PriceShowcase } from './price-showcase.js';

export type PriceShowcaseContainerProps = Omit<PriceShowcaseProps, 'description'> & {
    countrySlug: CountryType;
    professionSlug: Profession;
    currency: PriceCurrency;
    nextGroupStartsSoonText: string;
    joinBeforeStartDateText: string;
};

export const PriceShowcaseContainer: FunctionalComponent<PriceShowcaseContainerProps> = ({
    countrySlug,
    professionSlug,
    currency,
    nextGroupStartsSoonText,
    joinBeforeStartDateText,
    ...props
}) => {
    const { cohortDate } = useCohortDate(countrySlug, professionSlug);
    const prices = usePrices(countrySlug, professionSlug, currency);
    const formattedPrices = getFormattedPrices(prices, currency);

    const hasDiscount = (prices && prices.discount_percent > 0) ?? false;
    const description = cohortDate ? `${joinBeforeStartDateText} ${cohortDate}` : nextGroupStartsSoonText;
    const currencyCode = currency === 'BRL' ? '' : currency;

    return (
        <PriceShowcase
            {...props}
            description={description}
            PriceBlock={PriceBlock}
            prices={formattedPrices}
            currencyCode={currencyCode}
            hasDiscount={hasDiscount}
        />
    );
};
