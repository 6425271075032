import type { FunctionalComponent } from 'preact';

import { Button } from 'src/shared/components/button/button.js';
import { HtmlText } from 'src/shared/components/html-text/html-text.js';
import type { FormattedPriceInfo } from 'src/shared/types/prices.js';

import type { PriceBlockProps } from '../price-block/price-block.js';
import { PriceShowcaseLegal } from './__legal/price-showcase__legal.js';
import { cnPriceShowcase } from './price-showcase.constants.js';

import './price-showcase.css';

import './__blocks/price-showcase__blocks.css';
import './__button/price-showcase__button.css';
import './__description/price-showcase__description.css';
import './__title/price-showcase__title.css';
import './__wrapper/price-showcase__wrapper.css';

import './_accent-color/price-showcase_accent-color_green.css';
import './_accent-color/price-showcase_accent-color_orange.css';
import './_accent-color/price-showcase_accent-color_red.css';

type BlockAttributes = {
    titleBeforeIcon: string;
    titleAfterIcon: string;
    description: string;
    buttonLink: string;
    buttonText: string;

    leftPretitle: string;
    leftTitle: string;
    leftDescription: string;
    leftPartners: PriceBlockProps['partners'];

    rightPretitle: string;
    rightTitle: string;
    rightDescription: string;
    rightPartners: PriceBlockProps['partners'];

    accentColor: 'green' | 'orange' | 'red';

    prices: FormattedPriceInfo;
    currencyCode: string;
    paymentTypeTotal: string;
    paymentTypeMonthly: string;
    paymentMonths: string;
    PriceBlock: FunctionalComponent<PriceBlockProps>;
    hasDiscount: boolean;
    legal: string;
};

export interface PriceShowcaseProps extends BlockAttributes {}

export const PriceShowcase: FunctionalComponent<PriceShowcaseProps> = (props) => {
    const {
        titleBeforeIcon,
        titleAfterIcon,
        description,
        buttonLink,
        buttonText,

        leftPretitle,
        leftTitle,
        leftDescription,
        leftPartners,
        rightPretitle,
        rightTitle,
        rightDescription,
        rightPartners,

        accentColor,
        prices,
        currencyCode,
        paymentTypeMonthly,
        paymentTypeTotal,
        paymentMonths,
        PriceBlock,
        hasDiscount,
        legal,
    } = props;

    const paymentMonthsDescription = paymentMonths.replace('{months}', prices.months_count);

    return (
        <div className={cnPriceShowcase({ 'accent-color': accentColor })}>
            <div className={cnPriceShowcase('title')}>
                <HtmlText html={titleBeforeIcon} /> <i className="tripleten-emoji tripleten-emoji_glyph_face-smile" />{' '}
                <HtmlText html={titleAfterIcon} />
            </div>
            <div className={cnPriceShowcase('wrapper')}>
                <div className={cnPriceShowcase('blocks')}>
                    <PriceBlock
                        pretitle={leftPretitle}
                        title={`${leftTitle} ${prices.monthly_economy}`}
                        hasDiscount={hasDiscount}
                        discountPercent={prices.discount_percent}
                        partners={leftPartners}
                        description={`${leftDescription} ${paymentMonthsDescription}`}
                        paymentType={paymentTypeMonthly}
                        oldPrice={prices.monthly_price}
                        newPrice={prices.monthly_final_price}
                        currencyCode={currencyCode}
                        accentColor={accentColor}
                        colorTheme="light"
                    />
                    <PriceBlock
                        pretitle={rightPretitle}
                        title={`${rightTitle} ${prices.upfront_economy}`}
                        hasDiscount={hasDiscount}
                        discountPercent={prices.discount_percent}
                        partners={rightPartners}
                        description={rightDescription}
                        paymentType={paymentTypeTotal}
                        oldPrice={prices.full_price}
                        newPrice={prices.upfront_final_price}
                        currencyCode={currencyCode}
                        accentColor={accentColor}
                        colorTheme="dark"
                    />
                </div>
                {Boolean(legal) && <PriceShowcaseLegal>{legal}</PriceShowcaseLegal>}
            </div>
            <div className={cnPriceShowcase('description')}>
                <HtmlText html={description} />
            </div>
            <Button className={cnPriceShowcase('button')} href={buttonLink} mode="dark">
                {buttonText}
            </Button>
        </div>
    );
};
