import type { CountryType } from 'src/shared/types/countries.js';
import type { PriceCountry, PriceProfession } from 'src/shared/types/prices.js';
import type { Profession } from 'src/shared/types/professions.js';

export const PRICE_PROFESSION_MAP: Record<Profession, PriceProfession> = {
    'data-analyst': 'DA',
    'data-scientist': 'DS',
    web: 'WD',
    'qa-engineer': 'QA',
};

export const PRICE_COUNTRIES_MAP: Record<CountryType, PriceCountry> = {
    'es-chl': 'CH',
    'es-ecu': 'EC',
    'es-mex': 'MX',
    'es-per': 'PE',
    'pt-bra': 'BR',
};
